/*
  import { govDocStore } from '@/services/GoverningDocument/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await govDocStore.dispatch ('getGoverningDocumentCategoryList');
  */

  async getGoverningDocumentCategoryList({}) {
    console.log('in getGoverningDocumentCategoryList...')
    try {
      const results = await kms.get(`/GoverningDocuments/GoverningDocumentCategory/List`)

      if (isDebug == true)
        console.debug('getGoverningDocumentCategoryList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The Governing Document Category list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const govDocStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
