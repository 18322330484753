import { base64toBlob, showPdf } from '@/services/blobutility'
import Download from '../component/Download'
import _get from 'lodash/get'
import { documentCenterStore } from '@/services/DocumentCenter/store'
import { parseDate } from '@/utilities/Date/parse'
import { notifyMessage, notifyProblem, notifyWarning } from '@/services/notify'
import { govDocStore } from '@/services/GoverningDocument/store'

export const methods = {
  determineRows: async function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      const documentName = _get(entry, 'description', '')
      const createdDate = parseDate(_get(entry, ['createdDate'], ''), 'LL')

      return {
        documentName: documentName,
        uploaded: createdDate,
        document: {
          component: Download,
          props: {
            governingDocumentID: _get(entry, ['governingDocumentID'], 0),
            downloadPdf: governingDocumentID => {
              this.downloadPdf(governingDocumentID)
            }
          }
        }
      }
    })

    this.rows = rows
  },

  async downloadPdf(governingDocumentID) {
    if (this.isDebug == true) console.debug('downloadPdf...')

    if (!governingDocumentID || governingDocumentID == undefined) {
      notifyProblem('There is a problem downloading this document.')
    }

    try {
      const params = {
        governingDocumentID,
        asBase64: true,
        asRawBase64: true
      }

      await documentCenterStore.dispatch('getDocumentFile', {
        params,
        done: ({ details }) => {
          if (details) {
            if (this.isDebug == true) console.debug('base64pdf........................' + details)

            const contentType = 'application/pdf'
            const blob = base64toBlob(details, contentType)
            showPdf(blob)
            notifyMessage(`Successfully downloaded the governing document.`)
            return
          }
        }
      })
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
      notifyWarning('There was a problem downloading the statements of account.')
    }
  },

  async loadDocumentCenter() {
    if (this.isDebug == true) console.debug('in loadDocumentCenter()...')

    await documentCenterStore
      .dispatch('getDocumentCenterList', {
        hoaID: this.currentHoaId,
        governingDocumentCategoryID:
          this.governingDocumentCategoryID > 0 ? this.governingDocumentCategoryID : null
      })
      .then(async ({ list }) => {
        await this.determineRows(list)
      })
  },

  async loadGoverningDocumentCategories() {
    await govDocStore.dispatch('getGoverningDocumentCategoryList').then(async ({ list }) => {
      if (list) {
        let docCategories = []
        docCategories = list.map(m => ({
          ...m,
          label: m.name,
          value: m.governingDocumentCategoryID
        }))

        docCategories.push({ label: 'All', value: '' })
        this.governingDocumentCategories = docCategories
      }
    })
  },

  async reload() {
    this.loading = true

    this.rows = []
    await this.loadDocumentCenter()

    await this.loadGoverningDocumentCategories()

    this.loading = false
  }
}
