<template>
  <PageContentLayoutOnly role="region">
    <div class="column is-8" v-if="governingDocumentCategories">
      <valid-select
        label="Filter By Category"
        placeholder="Document Category"
        vid="governingDocumentCategoryID"
        v-model="governingDocumentCategoryID"
      >
        <option
          v-for="option in governingDocumentCategories"
          :value="option.value"
          :key="option.value"
          >{{ option.label }}</option
        >
      </valid-select>
    </div>
    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
      headerColor="#FFF"
      condensed
    >
    </ModernTable>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import { methods } from './keys/methods'
import { data } from './keys/data'
import ValidSelect from '@/components/inputs/ValidSelect'

export default {
  name: 'DocumentCenter',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    ValidSelect
  },

  data,

  methods,

  computed: {
    ...mapState({
      ...mapGetters('user', ['authUser', 'retrieveUser', 'currentHoaId'])
    })
  },

  watch: {
    governingDocumentCategoryID() {
      this.reload()
    }
  },

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { documentCenter: { title: 'Document Center' } }
    }
  }
}
</script>

<style></style>
